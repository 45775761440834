<template>
  <div class="container">
    <topHeader title="提交订单"></topHeader>
    <div class="top-content">
      <div class="top-content-btn" @click="toNutrition">营养分析</div>
    </div>

    <div class="common-container content">
      <div
        v-if="order.mode !== 'hall_meal'"
        class="address-content"
        @click="toAddress"
      >
        <!-- <div v-if="addressDefault.name">
          <img style="width:30px;height:30px" :src="address" alt>
        </div> -->
        <div v-if="addressDefault.name" class="address-default">
          <div class="address-default-content">
            <span class="default-name">
              {{ addressDefault.name }}
            </span>
            <span class="default-name">
              {{ addressDefault.addressPhone }}
            </span>
            <span v-if="addressDefault.isDefault === 1" class="default-title">
              默认
            </span>
          </div>
          <div class="address-default-img">
            <img class="default-img" :src="locationImg" alt />
            <span>{{ addressDefault.address }}</span>
          </div>
        </div>
        <div v-if="!addressDefault.name" class="address-empty">
          <img class="address-empty-img" :src="locationImg" alt />
          <span>请添加地址</span>
        </div>
        <div class="address-right">
          <img style="height: 100%; width: 100%" :src="rightImg" alt />
        </div>
      </div>

      <div class="main-container">
        <div class="main-title">已选菜品</div>
        <CommonBorder></CommonBorder>
        <div v-for="(item, index) in order.products" :key="index">
          <div class="product-items">
            <div class="items-img-content">
              <img
                class="items-img"
                style="width: 100%; height: 100%"
                :src="item.logo ? `${apiURL}/image?name=${item.logo}` : dish"
                alt=""
              />
            </div>
            <div class="items-info-content">
              <div class="items-info-name">{{ item.name }}</div>
              <div class="items-num-content">
                <div class="items-info-num">x{{ item.num }}</div>
                <div class="items-info-price">
                  ￥{{ (item.price / 100).toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
          <CommonBorder></CommonBorder>
        </div>
      </div>

      <div class="remark-content">
        <div class="remark-title">备注</div>
        <div class="remark-input">
          <van-field
            v-model="order.remark"
            rows="3"
            autosize
            style="background: #f2f2f2"
            type="textarea"
            maxlength="100"
            placeholder="请输入备注"
            show-word-limit
          />
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-total">
        <div class="footer-sum-content">
          <div class="sum-title">合计：</div>
          <div class="sum-price-content">
            <div class="sum-price">￥{{ (order.amount / 100).toFixed(2) }}</div>
            <div v-if="order.amount">
              <span class="sum-original-price">
                {{ (order.originalCost / 100).toFixed(2) }}
              </span>
            </div>
          </div>
        </div>
        <div class="footer-submit">
          <van-button class="footer-submit-btn" round @click.stop="onSubmit">
            立即支付
          </van-button>
        </div>
      </div>
    </div>

    <van-popup
      v-model="show2"
      position="bottom"
      class="pay-popup"
      :style="{ height: '45%', position: 'relative' }"
      :close-on-click-overlay="false"
    >
      <div class="pay-header">
        <div class="pay-header-img" @click="show2 = false">
          <img class="close-img" :src="closeImg" alt />
        </div>
        <div style="color: #666">支付信息</div>
      </div>
      <div class="pay-main">
        <div style="text-align: center">
          <div class="pay-main-price">
            {{
              order.amount
                ? (order.amount / 100).toFixed(2)
                : (order.originalCost / 100).toFixed(2)
            }}
          </div>
          <div class="pay-main-title">订餐金额(元)</div>
        </div>
      </div>
      <div class="pay-radio-content">
        <van-radio-group v-model="result">
          <van-cell-group>
            <van-cell
              v-if="!phone && !organizationId"
              :title="'卡内余额'"
              clickable
              @click="result = 1"
            >
              <!-- <template #label>
                  <span
                    style="margin-left:0.5rem"
                    v-if="!phone && !organizationId"
                  >(可用余额:￥{{(Number(user.balance+user.coupon+user.allowance)/100).toFixed(2)}})</span>
              </template>-->
              <template #right-icon>
                <van-radio checked-color="#FA5050" :name="1" />
              </template>
            </van-cell>
            <!-- <van-cell v-if="paymentSetting.icbcDcep&& paymentSetting.icbcDcep.appId !=='' && paymentSetting.icbcDcep.privateKey !==''" title="数字人民币" clickable @click="result = 2">
              <template #right-icon>
                <van-radio checked-color="#FA5050" :name="2" />
              </template>
            </van-cell>-->
            <van-cell
              v-if="phone && organizationId"
              :title="'微信支付'"
              clickable
              @click="result = 3"
            >
              <template #right-icon>
                <van-radio checked-color="#FA5050" :name="3" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="pay-footer">
        <van-button
          :loading="loadingOrder"
          class="pay-footer-btn"
          @click="paymentFn"
        >
          立即支付
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import rightImg from '@/assets/images/rightImg.png';
import locationImg from '@/assets/images/locationImg.png';
import closeImg from '@/assets/images/closeImg.png';
import dish from '@/assets/dish.png';
import cancel from '@/assets/cancel.png';
import address from '@/assets/address.png';
import PayClose from '@/assets/pay_close.png';
import {
  getAddressListAPI,
  weixinCanOrderAPI,
  getOrganizationDetailAPI,
  weixinCanOrderAPI1,
  getNutritionConfigAPI
} from '@/api/order';
import { mealOrder } from '@/api/order';
import { Toast } from 'vant';
import moment from 'moment';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';

export default {
  components: { topHeader, CommonBorder },
  data() {
    return {
      PayClose,
      dish,
      cancel,
      address,
      apiURL: process.env.VUE_APP_BASE_API,
      rightImg,
      locationImg,
      closeImg,
      order: {},
      user: {},
      phone: '',
      organizationId: '',
      addressDefault: this.$store.getters.addressDefault,
      show2: false,
      result: 1,
      isWhite: '',
      payData: {},
      loadingOrder: false
    };
  },
  created() {
    this.order = JSON.parse(this.$route.query.item);
    this.user = JSON.parse(sessionStorage.getItem('personInfo'));
    this.phone = sessionStorage.getItem('phone');
    this.organizationId = sessionStorage.getItem('organizationId');
    this.isWhite = sessionStorage.getItem('isWhite');

    if (this.user) {
      this.$store
        .dispatch('user/getInfo')
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
      this.result = 1;
    } else if (!this.user && !this.organizationId && !this.phone) {
      this.$router.push({ name: 'OrderLogin' });
    } else {
      this.result = 3;
    }
    if (
      this.order.mode !== 'hall_meal' &&
      Object.keys(this.addressDefault).length === 0
    ) {
      this.getAddressListFn();
    }
  },
  methods: {
    onSubmit() {
      if (!this.addressDefault.name && this.order.mode !== 'hall_meal') {
        Toast('请先添加地址!');
        return;
      }
      this.show2 = true;
    },
    toAddress() {
      this.$router.push({
        name: 'OrderAddress',
        query: { order: JSON.stringify(this.order) }
      });
    },
    toBack() {
      this.$router.back();
    },

    toNutrition() {
      getNutritionConfigAPI({
        name: this.order.type,
        organizationId: this.user.organization.id
      }).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.$router.push({
              name: 'Nutrition',
              query: {
                order: JSON.stringify(this.order),
                nutritionConfig: JSON.stringify(res.data)
              }
            });
          } else {
            const nutritionConfig = {
              // 碳水化合物
              carbohydrateMin: 30,
              carbohydrateMax: 65,
              // 蛋白质
              proteinMin: 30,
              proteinMax: 65,
              // 脂肪
              fatMin: 30,
              fatMax: 65,
              // 膳食纤维
              dietaryFiberMin: 30,
              dietaryFiberMax: 65
            };
            this.$router.push({
              name: 'Nutrition',
              query: {
                order: JSON.stringify(this.order),
                nutritionConfig: JSON.stringify(nutritionConfig)
              }
            });
          }
        }
      });
    },

    getAddressListFn() {
      getAddressListAPI({
        phone: this.phone ? this.phone : '',
        userId: this.user ? this.user.id : '',
        organizationId: this.user
          ? this.user.organization.id
          : this.organizationId
      }).then((res) => {
        if (res.code === 0) {
          res.data.map((item) => {
            if (item.isDefault === 1) {
              this.addressDefault = item;
            }
          });
        }
      });
    },
    paymentFn() {
      if (this.phone && this.organizationId) {
        const newOrder = {
          organizationId: this.organizationId,
          openid: localStorage.getItem('openid'),
          amountCyn: this.order.amount,
          phone: this.phone,
          mealId: this.order.mealId,
          mode: this.order.mode,
          products: this.order.products,
          address:
            this.order.mode !== 'hall_meal'
              ? `${this.addressDefault.name} ${this.addressDefault.addressPhone} ${this.addressDefault.address}`
              : '',
          canteenId: this.order.canteenId,
          remark: this.order.remark
        };
        localStorage.setItem('phone', this.phone);
        localStorage.setItem('organizationId', this.organizationId);
        localStorage.setItem('isWhite', false);
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        const before = moment(this.order.endTime).isBefore(now, 'second');
        if (before) {
          Toast('已过订餐时间，订餐失败！');
          return;
        }
        getOrganizationDetailAPI(this.organizationId).then((res) => {
          if (res.code === 0) {
            if (res.data.code === '1030') {
              weixinCanOrderAPI({ order: newOrder }).then((res) => {
                if (res.code === 0) {
                  this.payData = {
                    appId: res.data.appId,
                    timeStamp: res.data.timeStamp,
                    nonceStr: res.data.nonceStr,
                    package: res.data.packageVal,
                    signType: res.data.signType,
                    paySign: res.data.paySign
                  };
                  if (typeof WeixinJSBridge === 'undefined') {
                    if (document.addEventListener) {
                      document.addEventListener(
                        'WeixinJSBridgeReady',
                        this.onBridgeReady,
                        false
                      );
                    } else if (document.attachEvent) {
                      document.attachEvent(
                        'WeixinJSBridgeReady',
                        this.onBridgeReady
                      );
                      document.attachEvent(
                        'onWeixinJSBridgeReady',
                        this.onBridgeReady
                      );
                    }
                  } else {
                    this.onBridgeReady();
                  }
                }
                this.$store.commit('user/SET_ADDRESS_DEFAULT', {});
              });
            } else {
              weixinCanOrderAPI1({ order: newOrder }).then((res) => {
                if (res.code === 0) {
                  this.payData = {
                    appId: res.data.appId,
                    timeStamp: res.data.timeStamp,
                    nonceStr: res.data.nonceStr,
                    package: res.data.packageVal,
                    signType: res.data.signType,
                    paySign: res.data.paySign
                  };
                  if (typeof WeixinJSBridge === 'undefined') {
                    if (document.addEventListener) {
                      document.addEventListener(
                        'WeixinJSBridgeReady',
                        this.onBridgeReady,
                        false
                      );
                    } else if (document.attachEvent) {
                      document.attachEvent(
                        'WeixinJSBridgeReady',
                        this.onBridgeReady
                      );
                      document.attachEvent(
                        'onWeixinJSBridgeReady',
                        this.onBridgeReady
                      );
                    }
                  } else {
                    this.onBridgeReady();
                  }
                }
                this.$store.commit('user/SET_ADDRESS_DEFAULT', {});
              });
            }
          }
        });
      } else {
        const order = {
          ...this.order,
          address:
            this.order.mode !== 'hall_meal'
              ? `${this.addressDefault.name} ${
                  this.addressDefault.addressPhone
                    ? this.addressDefault.addressPhone
                    : this.user.phone
                } ${this.addressDefault.address}`
              : ''
        };
        this.loadingOrder = true;
        mealOrder({ order }).then((res) => {
          if (res.code === 0) {
            this.loadingOrder = false;
            Toast.success('预定成功');
            this.$router.push({ name: 'Order' });
          } else {
            this.loadingOrder = false;
          }
          this.$store.commit('user/SET_ADDRESS_DEFAULT', {});
        });
      }
    },
    onBridgeReady() {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          ...this.payData
        },
        (res) => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            Toast('支付成功!');
            this.show2 = false;
            const toast = Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: `支付成功!\n3秒后回到订餐页面!`
            });

            let second = 3;
            const timer = setInterval(() => {
              second--;
              if (second) {
                toast.message = `支付成功!\n${second}秒后回到订餐页面!`;
              } else {
                clearInterval(timer);
                // 手动清除 Toast
                Toast.clear();
                this.$router.push('Order');
              }
            }, 1000);
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            Toast('已取消!');
            this.show2 = false;
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            Toast('支付失败!');
            this.show2 = false;
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-content {
    display: flex;
    align-items: center;
    height: 44px;
    box-sizing: border-box;
    padding: 0 15px;
    background: #fff;
    justify-content: center;
    color: #333;
    position: relative;
    .top-content-btn {
      position: absolute;
      right: 16px;
      background: #fa5050;
      padding: 0px 10px;
      color: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      height: 30px;
      line-height: 30px;
    }
  }
  .content {
    // width: 100%;
    flex: 1;
    background: #f7f7f7;
    // padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    margin: 0 16px;
    .address-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      margin-bottom: 15px;
      box-sizing: border-box;
      padding: 18px 16px;
      border-radius: 10px;
      .address-default {
        .address-default-content {
          font-size: 16px;
          color: #333;
          margin-bottom: 12px;
          .default-name {
            margin-right: 8px;
          }
          .default-title {
            font-size: 13px;
            color: #fff;
            background: #fa5050;
            padding: 2px 8px;
            border-radius: 4px;
          }
        }
        .address-default-img {
          font-size: 16px;
          color: #666;
          height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          vertical-align: bottom;
          .default-img {
            height: 16px;
            width: 16px;
            margin-right: 6px;
            vertical-align: bottom;
          }
        }
      }
      .address-empty {
        height: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #666;
        font-size: 16px;
        .address-empty-img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
      .address-right {
        height: 24px;
        height: 24px;
      }
    }

    .main-container {
      border-radius: 10px;
      background: #fff;
      color: #333;
      .main-title {
        box-sizing: border-box;
        padding: 0 16px;
        height: 40px;
        line-height: 40px;
        color: #333;
      }
      .product-items {
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .items-img-content {
          width: 70px;
          height: 70px;
          .items-img {
            border-radius: 5px;
          }
        }
        .items-info-content {
          flex: 1;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 70px;
          align-content: space-between;
          .items-info-name {
            font-size: 16px;
            color: #333;
          }
          .items-num-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .items-info-num {
              font-size: 13px;
              color: #666;
            }
            .items-info-price {
              font-size: 16px;
              color: #333;
            }
          }
        }
      }
    }

    .remark-content {
      border-radius: 10px;
      background: #fff;
      color: #333;
      margin-top: 16px;
      box-sizing: border-box;
      padding-bottom: 28px;
      .remark-title {
        box-sizing: border-box;
        padding: 0 16px;
        height: 40px;
        line-height: 40px;
        color: #333;
      }
      .remark-input {
        box-sizing: border-box;
        padding: 0 16px;
      }
    }
  }
  .footer {
    height: 60px;
    .footer-total {
      z-index: 2000;
      position: fixed;
      bottom: 0;
      background-color: #fff;
      width: 100%;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 60px;
      .footer-sum-content {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        .sum-title {
          color: #999;
          font-size: 16px;
        }
        .sum-price-content {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          .sum-price {
            color: #f00;
            font-size: 16px;
          }
          .sum-original-price {
            font-size: 12px;
            color: #999;
            text-decoration: line-through #999;
            -webkit-text-decoration: line-through #999;
            margin-left: 8px;
          }
        }
      }
      .footer-submit {
        width: 100px;
        height: 40px;
        .footer-submit-btn {
          width: 100%;
          height: 40px;
          background: #fa5050;
          color: #fff;
        }
      }
    }
  }
  .payment_popup {
    width: 100%;
    background: rgba(248, 248, 248);
    .payment_in {
      padding: 0.5rem;
      .payment_top {
        text-align: right;
        height: 1.5rem;
        img {
          width: 1.5rem;
        }
      }
      .payment_money {
        display: flex;
        justify-content: center;
        color: #000;
        h1 {
          margin: 0;
          font-weight: 900;
        }
        p {
          font-weight: 900;
          margin-left: 0.5rem;
        }
      }
      .payment_order {
        height: 2rem;
        padding: 0 1rem;
        background: rgba(255, 255, 255);
        line-height: 2rem;
        font-size: 0.9rem;
        color: #000;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        p {
          height: 2rem;
          margin: 0;
        }
      }
      .payment_select {
        padding: 0 0.5rem;
        background: rgba(255, 255, 255);
        font-size: 1.5rem;
      }
      .payment_btn {
        position: fixed;
        bottom: 0.5rem;
        width: 100%;
        text-align: center;
        .van-button {
          width: 80%;
          background: rgba(238, 130, 128);
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
  .pay-popup {
    .pay-header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: #333;
      border-bottom: 1px #e5e5e5 solid;
      padding: 12px;
      box-sizing: border-box;
      .pay-header-img {
        position: absolute;
        left: 16px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        .close-img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .pay-main {
      padding: 12px;
      box-sizing: border-box;
      color: #333;
      .pay-main-price {
        font-size: 30px;
        margin-bottom: 10px;
      }
      .pay-main-title {
        font-size: 12px;
        color: #666;
      }
    }
    .pay-footer {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      position: absolute;
      bottom: 20px;
      .pay-footer-btn {
        width: 100%;
        background: #fa5050;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}
::v-deep .van-field__control {
  background: #f2f2f2;
}
</style>
